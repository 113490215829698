<template>
    <div class="add">
        <el-form
            ref="form"
            style="
                flex: 1;
                margin-top: 20px;
                margin-left: 40px;
                margin-bottom: 80px;
            "
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="合同标题" required>
                <el-input
                    style=""
                    v-model="form.contractTitle"
                    size="small"
                    placeholder="请输入合同标题"
                    clearable=""
                ></el-input>
            </el-form-item>
            <el-form-item label="客户" required>
                <el-select
                    v-model="form.customerId"
                    size="small"
                    style="width: 270px"
                    clearable
                    placeholder="请选择"
                    @change="onEach()"
                >
                    <el-option
                        v-for="item in Relation"
                        :key="item.id"
                        :label="item.companyName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="甲方" required>
                <el-input
                    style=""
                    v-model="form.contractA"
                    size="small"
                    placeholder="请输入甲方"
                    clearable=""
                ></el-input>
            </el-form-item>
            <el-form-item label="打款人">
                <el-input
                    v-model="form.payContract"
                    size="small"
                    placeholder="请输入打款人"
                    clearable=""
                ></el-input>
            </el-form-item>
            <el-form-item label="乙方" required>
                <el-select
                    v-model="form.typeB"
                    size="small"
                    style="width: 270px"
                    placeholder="请选择乙方"
                    clearable
                >
                    <el-option
                        v-for="item in $tableDataHandle.selectTypeB()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="form.typeB == 9">
                <el-input
                    v-model="contractB"
                    size="small"
                    placeholder="乙方"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="合同金额" required>
                <el-input
                    style=""
                    v-model="form.contractAmount"
                    size="small"
                    placeholder="请输入合同金额"
                    clearable=""
                ></el-input>
            </el-form-item>
            <el-form-item label="订单类型" required>
                <el-select
                    v-model="form.orderType"
                    size="small"
                    style="width: 270px"
                    placeholder="请选择订单类型"
                    clearable
                >
                    <el-option label="新增" value="1"></el-option>
                    <el-option label="续费" value="2"></el-option>
                    <el-option label="扩用户" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="营业执照">
                <el-upload
                    drag
                    :on-progress="progress"
                    :action="action"
                    class="avatar-uploader"
                    list-type="picture-card"
                    :on-success="onBusinessLicense"
                    :before-upload="beforeAvatarUploads"
                    multiple
                    :limit="1"
                    ref="businessLicense"
                    accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                    :file-list="businessLicense"
                >
                    <i slot="default" class="el-icon-plus"></i>
                    <div
                        slot="file"
                        slot-scope="{ file }"
                        style="text-align: center"
                    >
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="form.businessLicenseUrl"
                            alt=""
                            v-if="
                                form.businessLicenseUrl.indexOf('.jpg') != -1 ||
                                form.businessLicenseUrl.indexOf('.png') != -1 ||
                                form.businessLicenseUrl.indexOf('.gif') != -1
                            "
                        />
                        <span
                            v-if="
                                form.businessLicenseUrl.indexOf('.pdf') != -1 ||
                                form.businessLicenseUrl.indexOf('.PDF') != -1
                            "
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            v-if="form.businessLicenseUrl.indexOf('.doc') != -1"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                                v-if="
                                    form.businessLicenseUrl.indexOf('.jpg') !=
                                        -1 ||
                                    form.businessLicenseUrl.indexOf('.png') !=
                                        -1 ||
                                    form.businessLicenseUrl.indexOf('.gif') !=
                                        -1
                                "
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)"
                            >
                                <i class="el-icon-download"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file)"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </el-upload>
            </el-form-item>

            <el-form-item label="附件" required>
                <el-upload
                    drag
                    :on-progress="progress"
                    style=""
                    :action="action"
                    class="avatar-uploader"
                    list-type="picture-card"
                    :on-success="onAttachment"
                    :before-upload="beforeAvatarUploads"
                    multiple
                    :file-list="fileList"
                    :limit="5"
                    ref="attachment"
                    accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                >
                    <i slot="default" class="el-icon-plus"></i>
                    <div
                        slot="file"
                        slot-scope="{ file }"
                        style="text-align: center"
                    >
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            alt=""
                            v-if="
                                file.url &&
                                (file.url.indexOf('.jpg') != -1 ||
                                    file.url.indexOf('.png') != -1 ||
                                    file.url.indexOf('.gif') != -1)
                            "
                        />
                        <span
                            v-if="
                                file.url &&
                                (file.url.indexOf('.pdf') != -1 ||
                                    file.url.indexOf('.PDF') != -1)
                            "
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span v-if="file.url && file.url.indexOf('.doc') != -1">
                            <img src="@/img/word.png" alt="" />
                        </span>
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                                v-if="
                                    file.url &&
                                    (file.url.indexOf('.jpg') != -1 ||
                                        file.url.indexOf('.png') != -1 ||
                                        file.url.indexOf('.gif') != -1)
                                "
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)"
                            >
                                <i class="el-icon-download"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleRemoves(file)"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    v-model="form.remark"
                    size="small"
                    placeholder="请输入备注"
                    clearable=""
                ></el-input>
            </el-form-item>
            <p style="font-size: 14px; padding-bottom: 20px">邮箱类产品信息</p>
            <el-form-item label="邮箱版本">
                <el-select
                    v-model="form.emailVersionType"
                    size="small"
                    style="width: 270px"
                    placeholder="请选择邮箱版本"
                    clearable
                >
                    <el-option label="全新用户" value="1"></el-option>
                    <el-option label="已有新版基础版" value="2"></el-option>
                    <el-option label="已有旧版基础版" value="3"></el-option>
                    <el-option label="阿里-标准版" value="4"></el-option>
                    <el-option label="阿里-尊享版" value="5"></el-option>
                    <el-option label="阿里-集团版" value="6"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类型信息">
                <el-input
                    v-model="form.emailVersionTypeName"
                    size="small"
                    placeholder="请输入类型信息"
                    clearable=""
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                :loading="loading"
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保存</el-button
            >
        </div>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <el-dialog
            :visible.sync="drawerDeclare"
            width="560px"
            append-to-body
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    确认编辑
                </div>
            </template>
            <p class="operationText">
                该合同已审核，编辑后的合同需要重新提交审核，确定编辑？
            </p>
            <div class="bottom-buts">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    @click="onSubmitEdit"
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 25px;
                    "
                    size="mini"
                    >确认</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { meCustomerList } from '@/api/user/ae/customer';
import { contractEdit, deleteBusinessLicense } from '@/api/user/ae/contract.js';
export default {
    data() {
        return {
            data: '',
            form: {
                contractTitle: '',
                customerId: '',
                contractA: '',
                payContract: '',
                typeB: '',
                contractAmount: '',
                businessLicenseUrl: '',
                remark: '',
                emailVersionType: '',
                emailVersionTypeName: '',
                orderType: '',
            },
            action: this.$url.url() + '/file/local/upload/contract',
            Relation: [],
            contractB: '',
            disabled: false,
            dialogVisible: false,
            drawerDeclare: false,
            dialogImageUrl: '',
            fileList: [],
            fileArr: [],
            businessLicense: [],
            loading: false,
        };
    },

    methods: {
        getRelation(row) {
            this.data = row;
            this.fileList = [];
            this.form.id = row.id;
            this.form.contractTitle = row.contractTitle;
            this.form.customerId = row.customerId;
            this.form.contractA = row.contractA;
            this.form.payContract = row.payContract;
            this.form.contractAmount = row.contractAmount;
            this.form.remark = row.remark;
            this.form.emailVersionTypeName = row.emailVersionTypeName;
            this.form.typeB = row.typeB;
            this.form.orderType = String(row.orderType);
            this.form.emailVersionType = String(row.emailVersionType);
            this.form.businessLicenseUrl = row.businessLicenseUrl;
            this.businessLicense = [];
            if (row.businessLicenseUrl) {
                this.businessLicense = [{ url: row.businessLicenseUrl }];
            }
            // console.log(this.form.businessLicenseUrl);
            for (let i in row.contractAttachmentList) {
                this.fileList.push({
                    url: row.contractAttachmentList[i].attachmentUrl,
                });
            }
            let data = {
                param: {
                    customerStatusList: [1, 2, 3],
                    followAdminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 0,
                pageSize: 0,
            };
            meCustomerList(data).then((res) => {
                this.Relation = res.data.list;
                // console.log(row);
            });
        },
        onSubmit() {
            if (this.data.status == 9) {
                this.drawerDeclare = true;
            } else {
                this.submit();
            }
        },
        submit() {
            if (!this.form.contractTitle) {
                this.$message.error('请输入合同标题');
            } else if (!this.form.customerId) {
                this.$message.error('请选择客户');
            } else if (!this.form.contractA) {
                this.$message.error('请输入甲方');
            } else if (!this.form.typeB) {
                this.$message.error('请选择乙方');
            } else if (this.form.typeB == 9 && !this.contractB) {
                this.$message.error('请输入乙方');
            } else if (!this.form.contractAmount) {
                this.$message.error('请输入合同金额');
            } else if (this.fileList.length == 0) {
                this.$message.error('请上传附件');
            } else {
                let data = {
                    param: this.form,
                };
                if (this.form.typeB == 9) {
                    data.param.contractB = this.contractB;
                }
                data.param.contractAttachmentList = [];
                for (let i in this.fileList) {
                    data.param.contractAttachmentList.push({
                        attachmentUrl: this.fileList[i].url,
                    });
                }
                contractEdit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                    }
                    this.onClose();
                });
            }
        },
        onSubmitEdit() {
            this.submit();
            this.drawerDeclare = false;
            this.$router.push('/storage');
        },
        handleClose() {
            this.drawerDeclare = false;
        },
        onEach() {
            for (let i in this.Relation) {
                if (this.Relation[i].id == this.form.customerId) {
                    return (this.form.contractA = this.Relation[i].companyName);
                } else {
                    this.form.contractA = '';
                }
            }
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.$refs['attachment'].uploadFiles = [];
            this.$refs['businessLicense'].uploadFiles = [];
            this.$emit('close');
        },
        progress() {
            this.loading = true;
        },
        // 上传营业执照
        onBusinessLicense(response) {
            this.loading = false;
            if ((response.code = 200)) {
                this.$message.success('营业执照上传成功');
                this.form.businessLicenseUrl = response.data;
            } else {
                this.$message.success('营业执照上传失败');
            }
        },
        // 上传附件
        onAttachment(response) {
            this.fileArr.push({ url: response.data });
            let a = JSON.stringify(this.fileList);
            let b = JSON.parse(a);
            // console.log(b);
            setTimeout(() => {
                this.fileList = this.fileArr;
                for (let i in b) {
                    this.fileList.push(b[i]);
                }
                let obj = {};
                this.fileList = this.fileList.reduce((cur, next) => {
                    obj[next.uid]
                        ? ''
                        : (obj[next.uid] = true && cur.push(next));
                    return cur;
                }, []);
                // console.log(this.fileList);
                this.loading = false;
            }, 2000);
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            return isLt2M;
        },
        // 预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 下载
        handleDownload(file) {
            if (
                file.url.indexOf('.doc') != -1 ||
                file.url.indexOf('.docx') != -1
            ) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        file.url
                );
            } else {
                window.open(file.url, '_blank');
            }
        },
        // 营业执照删除
        handleRemove(file) {
            let data = {
                param: {
                    id: this.data.id,
                },
            };
            deleteBusinessLicense(data).then((res) => {
                if (lres.code == 200) {
                    this.$message.success('删除成功');
                }
            });
            this.$refs['businessLicense'].uploadFiles = [];
            this.form.businessLicenseUrl = '';
            this.businessLicense = [];
        },
        // 附件删除
        handleRemoves(file) {
            for (let i in this.fileList) {
                if (this.fileList[i].url == file.url) {
                    this.fileArr.splice(i, 1);
                }
            }
            this.fileList = this.fileArr;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add {
    width: 100%;
    .el-input {
        width: 270px;
    }
}
.bottom-but {
    width: 100%;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
.el-upload img {
    width: 120px;
    height: 120px;
}
/deep/ .avatar-uploader .el-upload {
    border: none;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #f3f6fb;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
/deep/ .el-upload-dragger {
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
/deep/.avatar-uploader-icon {
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 80px;
    text-align: center;
}
/deep/ .el-upload-list__item.is-success {
    width: 120px;
    height: 120px;
}
.bottom-buts {
    width: 100%;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    margin-top: 16px;
    text-align: right;
    button {
        width: 90px;
    }
}
.operationText {
    text-align: center;
    padding: 50px 0 100px 0;
    font-size: 16px;
    color: #333;
}
</style>
